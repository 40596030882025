import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class GetTransectionDataService {

  constructor(private http: HttpClient) { }

  getService(UUID,token) {
    let headers = 
    new HttpHeaders({'Authorization':'Bearer '+token});
    return this.http.get<void>(environment.apInfo.apiUrl+'registrations/'+environment.apInfo.versionVar+'/info/'+UUID,{headers:headers});
  }
}
