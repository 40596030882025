import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class SubscribeServiService {

  constructor(private http: HttpClient) { }
  getSubscriptionService(practiceNumber,uniqueid,token) {
    let URL;
    let headers = 
    new HttpHeaders({'Authorization':'Bearer '+token});
    URL = environment.apInfo.apiUrl+'registrations/'+environment.apInfo.versionVar+'/subscription/'+practiceNumber+'?uniqueId='+uniqueid;
    return this.http.get<void>(URL,{headers:headers});
    }
}
