// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apInfo:{
    /*apiUrl:'https://api-stage.cgmsa.co.za:443/',
    authUrl:'https://api-stage.cgmsa.co.za:443/',*/
    
    apiUrl:'https://api-stage.cgmsa.co.za/',
    authUrl:'https://am-mgt-stage.cgmsa.co.za/oauth2/',
    Key:'FFlPIHpVpX8fcCpKA510gCJeDNQa',
    Secret:'fswzYap8o21yorR5tit5XL_xq4Ea',
   callBack:'https://stage-recomed.cgmsa.co.za',
    logoutRedirect:'https://stage-recomed.cgmsa.co.za',
	versionVar: 'V2.0',
	  doctorSearchVersion: 'V1.0',
	  practiceSearchVersion: 'V1.0',
  }
};
/*
export const environment = {
  production: true,
  apInfo:{
    apiUrl:'https://api.cgmsa.co.za:443/',
    authUrl:'https://api.cgmsa.co.za:443/',
    Key:'tzywcIqk4agwcOuJCswcytwe2nQa',
    Secret:'ATghL0m4GBWfwlafySuoqIa2V3Ea',
    callBack:'https://recomed.cgmsa.co.za',
    logoutRedirect:'https://recomed.cgmsa.co.za',
	versionVar: 'V2.0',
	doctorSearchVersion: 'V2.0',
	practiceSearchVersion: 'V2.0'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
