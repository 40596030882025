
import { GetPracticeidService } from './get-practiceid.service';
import { GeneratePracticeidService } from './generate-practiceid.service';
import { UploadCertificateService } from './upload-certificate.service';
import { environment } from './../environments/environment';
import { TokenService } from './token/token.service';
import { ChangeDetectorRef } from '@angular/core';
import { PracticeTypeService } from './practice-type.service';
import { ActivatedRoute } from '@angular/router';
import { RegisterServiService } from './register-servi.service';
import { ServiceListService } from './service-list.service';
import { SubscribeServiService } from './subscribe-servi.service';
import { DoctorsearchService } from './adminservice/doctorsearch.service';
import { PracticesearchService } from './adminservice/practicesearch.service';
import { Component, OnInit , NgZone } from '@angular/core';
import { map } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';
import jwt_decode from 'jwt-decode';
import { JsonPipe } from '@angular/common';
import{ActivatenexionService} from './activatenexion.service';
import {GetTransectionDataService} from "../app/get-transection-data.service";
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  CODE;UUID;nexionData:any;recomedData:any;
  selectedOption: string;displayErrorMessage :string;showError: boolean= false;sendQuery =true;labelbtn= "Activate";
  idtoken;loggedin_user;apitoken;registerData:any;register_Date;status;nexion_status;rid_value;key_value;dispalySuccessMessge;activationDate:any;
  constructor(private zone: NgZone,private activateNexion:ActivatenexionService, private getData:GetTransectionDataService, private changeDetector:ChangeDetectorRef, private pracList: PracticeTypeService,private getPracticeidService:GetPracticeidService,private generatePracticeidService:GeneratePracticeidService, private uploadcertificate: UploadCertificateService, private tokenService:TokenService, private registerUser:RegisterServiService, private practiceService: PracticesearchService,private doctorService:DoctorsearchService, private subsribeservice: SubscribeServiService, private servicelist: ServiceListService, private _activatedROute: ActivatedRoute) {
   this._activatedROute.queryParams.subscribe(queryParams => {
      /**
       * Code for UUID
       */
      const queryString = window.location.search;
      console.log(queryString);
      const urlParams = new URLSearchParams(queryString);
      this.CODE = urlParams.get('code');
     // alert(this.CODE)
      this.UUID = urlParams.get("uuid");
      
      if(this.UUID != null || this.UUID != undefined){
        
        //this.UUID = urlParams.get("uuid");
        localStorage.setItem("uuid",this.UUID);

        if(this.CODE == null){
          location.href = environment.apInfo.authUrl+'authorize?response_type=code&client_id='+environment.apInfo.Key+'&scope=openid&state=1234&redirect_uri='+environment.apInfo.callBack;
      
        }
      }
      
        
     // alert("CODE"+this.CODE+"UUID is " + this.UUID);
      
      if (this.CODE != null) {
         this.tokenService.callToken(this.CODE).pipe(map((res: any) => {
           $('#container-div').css("display", "block");
           this.setData(res);
           this.UUID =  localStorage.getItem("uuid");
         //  alert(this.UUID+ "UUID after ")
          this.getData.getService(this.UUID,this.apitoken).pipe(map((res: any) => { 
            localStorage.removeItem("uuid");
            this.nexionData = res;
           //alert(JSON.stringify(this.nexionData));
            this.subsribeservice.getSubscriptionService(this.nexionData.practiceNumber,this.nexionData.uniqueId,this.apitoken).pipe(map((resp: any) => { 
              
            
            
                    // this.nexionData = null;
                    this.registerData = resp;
                     
                     this.getDateStatus(this.registerData);
                
               
            //  alert(this.registerData+"registerData");
            })).subscribe();
            console.log(JSON.stringify(res));
          })).subscribe();
        })).subscribe(
          data => {
          },
          err => location.href = environment.apInfo.authUrl+'authorize?response_type=code&client_id='+environment.apInfo.Key+'&scope=openid&state=1234&redirect_uri='+environment.apInfo.callBack
          ); 
        
      }
    });
    //{"practiceNumber":"123","uniqueId":"123","doctorNumber":"MP1234567","serviceVendor":{"id":1,"name":"Nexion","contactEmail":"andre.koorsen@cgm.com","contactNumber":"0210010001","vendorStatus":"ACTIVE"},"serviceCode":"PAYMENT_LINK","email":"andre.koorsen@cgm.com","practiceType":"14","username":"PlebOne","practiceName":"BestPractice","practiceEmail":"andre.koorsen@cgm.com","practicePhysicalAddress":"1 Century City drive, Century City, 7441","practiceContactNumber":"0210000001"}
      //document.getElementById("registeruser").setAttribute('disabled','true');
    if((location.href).includes('localhost') || (location.href).includes('test-subscriptions') || (location.href).includes('stage-subscriptions')){
      //this.hideEmail = true;
    }
  }
  
  ngOnInit() {
    
  }
  public onChange(event): void {  // event will give you full breif of action
    const newVal = event.target.value;
    console.log(newVal);
    if(newVal != "Activate"){
      this.sendQuery = true;
    }
    if(newVal == "Activate"){
      this.sendQuery = false;
    }
  }

  getDateStatus(data){
    for(var i=0;i<data.length;i++){
      if(data[i].service.code=="RECOMED"){
        this.zone.runOutsideAngular(() => {
        this.register_Date=data[i].subscriptionDate;
        this.status= data[i].subscriptionStatus;
        this.activationDate = data[i].activationDate;
        });
        
      }
    }
  }
  activate(){
    this.showError =false;
    if(this.sendQuery == false){
      if((this.key_value != null || this.key_value != "" || this.key_value != undefined) &&
      (this.rid_value != null || this.rid_value != "" || this.rid_value != undefined)){
        $("#registerModal").modal()
      }else{
        this.showError =true;
        return;
      }
      
    }else{
      $("#registerModal").modal()
    }
    
  }
setData(data){
  console.log("decoded Value : "+JSON.stringify(data));
  this.idtoken = data.id_token;
  this.apitoken = data.access_token;
  
  var decoded:any = jwt_decode(this.idtoken); 
  this.loggedin_user = decoded.sub;
  console.log("decoded Value : "+JSON.stringify(decoded));
}
continueRegister(){

var data :any =[];
data= {
"uniqueId":this.nexionData.uniqueId,    
"practiceNumber": this.nexionData.practiceNumber,
"doctorNumber":this.nexionData.doctorNumber,
"serviceCode": "RECOMED",
"status": "ACTIVE",  
"practiceEmail": this.nexionData.email,
"practitionerId":this.selectedOption,
"workplaceId":this.rid_value,
"username":this.key_value
}
  this.activateNexion.activate(this.apitoken,data).pipe(map((res: any) => { 
    $("#registerModal").modal('hide');
    this.dispalySuccessMessge = "Record Successfully updated."
    this.subsribeservice.getSubscriptionService(this.nexionData.practiceNumber,this.nexionData.uniqueId,this.apitoken).pipe(map((resp: any) => { 

        this.registerData = resp;
        this.getDateStatus(this.registerData);
          
     
      //this.register_Date = 

    //  alert(this.registerData+"registerData");
    })).subscribe();
    console.log(JSON.stringify(res));
  })).subscribe();
}

}
