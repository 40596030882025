import { GetPracticeidService } from './../get-practiceid.service';
import { GeneratePracticeidService } from './../generate-practiceid.service';
import { UploadCertificateService } from './../upload-certificate.service';
import { environment } from './../../environments/environment';
import { TokenService } from './../token/token.service';
import { ChangeDetectorRef } from '@angular/core';
import { PracticeTypeService } from './../practice-type.service';
import { ActivatedRoute } from '@angular/router';
import { RegisterServiService } from './../register-servi.service';
import { ServiceListService } from './../service-list.service';
import { SubscribeServiService } from './../subscribe-servi.service';
import { DoctorsearchService } from './../adminservice/doctorsearch.service';
import { PracticesearchService } from './../adminservice/practicesearch.service';
import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';
import jwt_decode from 'jwt-decode';
import { JsonPipe } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-adminpanel',
  templateUrl: './adminpanel.component.html',
  styleUrls: ['./adminpanel.component.css']
})

export class AdminpanelComponent implements OnInit {
  practiceName; practiceNumber;doctorNumber;doctorName;
  practiceData;practiceInfo;doctorData;doctorInfo;subscribedServices:any=[];finalService:any=[];
  allavailableService:any=[]; fileData: File = null; previewUrl: any = null; previewCertUrl: any = null;
  subscriberdServices:any = [];selected :any;selectedDoctor:any;hideEmail =false;doctoremailValue;emailValue;
  doctorSeachNumber;private CODE;practiceNumberSearch = 0;username;successMessage:any=[];failureMessage:any=[];registrationData :any= [];
  allService = [];uploadcerts  = false;disciplineID="";practiceList:any=[];nopractice = false;practiceWithoutDoctor:any=[];dialogmessage = "";
  idtoken;loggedin_user;apitoken;showPracticeSearch =false;showInputFields =false;
  practice_orgname;practice_postaladdress;practice_physicaladdress;practice_contactnumber;practice_service;practice_number;
  pracTypeList=[];pracCode;practiceTypeCode;capturedocInput= false;docfirstName:string;
  disableDocInput = true;disabledPracInput = true;practice_uniqueID :string="";
  doclastName:string;docdisciplineDescription:string;docpersonalNumber:string;docphysicalAddress:string;docpostalAddress:string;
  docdoctorNumber:string;applicationType:string;
  constructor(private changeDetector:ChangeDetectorRef, private pracList: PracticeTypeService,private getPracticeidService:GetPracticeidService,private generatePracticeidService:GeneratePracticeidService, private uploadcertificate: UploadCertificateService, private tokenService:TokenService, private registerUser:RegisterServiService, private practiceService: PracticesearchService,private doctorService:DoctorsearchService, private subsribeservice: SubscribeServiService, private servicelist: ServiceListService, private _activatedROute: ActivatedRoute) {
   this._activatedROute.queryParamMap.subscribe(queryParams => {
      /**
       * Code for UUID
       */
      this.CODE = queryParams.get("code");
      if (this.CODE != null || this.CODE != "" || this.CODE != undefined) {
         this.tokenService.callToken(this.CODE).pipe(map((res: any) => {
           $('#container-div').css("display", "block");
           this.setData(res);
        })).subscribe(
          data => {
          },
          err => location.href = environment.apInfo.authUrl+'authorize?response_type=code&client_id='+environment.apInfo.Key+'&scope=openid&state=1234&redirect_uri='+environment.apInfo.callBack
          ); 
        
      }});
      //document.getElementById("registeruser").setAttribute('disabled','true');
    if((location.href).includes('localhost') || (location.href).includes('test-subscriptions') || (location.href).includes('stage-subscriptions')){
      this.hideEmail = true;
    }
  }
  
  ngOnInit() {
    this.practiceList = [];this.pracTypeList=[];
    this.showInputFields = true;
    this.pracList.getListService().pipe(map((res: any) => {
      res.forEach(element => {
        this.pracTypeList.push(element.typename);
      });

      this.pracTypeList.sort();
    })).subscribe((data) =>{ 

    });
  }
  toggleDocInputField(event){
    
    if (!event.target.checked) {
      this.disableDocInput = true;
    }else{
      //this.practiceList = [];
      this.disableDocInput = false;
      this.docfirstName = "";
      this.doclastName = "";
      this.docdisciplineDescription = "";
      this.docdoctorNumber = "";
      this.docpersonalNumber = "";
      this.docphysicalAddress = "";
      this.docpostalAddress = "";
    }
    this.capturedocInput = !this.capturedocInput;
  }

  toggleInputField(event){
    
    if (!event.target.checked) {
      this.disabledPracInput = true;
    }else{
      this.practiceList = [];
      this.disabledPracInput = false;
      this.practiceInfo = [];
      this.practice_number = "";
      this.practice_uniqueID = "";
      this.practice_orgname = "";
      this.practice_service = "";
      this.practice_contactnumber = "";
      this.practice_physicaladdress = "";
      this.practice_postaladdress = "";
    }
    this.showInputFields = !this.showInputFields;
  }
setData(data){
  console.log("decoded Value : "+JSON.stringify(data));
  this.idtoken = data.id_token;
  this.apitoken = data.access_token;
  
  var decoded:any = jwt_decode(this.idtoken); 
  this.loggedin_user = decoded.sub;
  console.log("decoded Value : "+JSON.stringify(decoded));
}
  hide(){
    //document.getElementById("container-div").style.opacity = "0.5";
    document.getElementById("loader").style.display = "none";
    document.getElementById("div-loader").style.display = "none";
  }
  show(){
    //console.log("ato b"+ atob(JSON.parse(this.idtoken)));
    
    //document.getElementById("container-div").style.opacity = "1";
    document.getElementById("loader").style.display = "block";
    document.getElementById("div-loader").style.display = "block";
  }
 
  findPractice(){
    if(this.practiceName==undefined){
      this.practiceName='';
    }
    if( this.practiceNumber==undefined){
      this.practiceNumber='';
    }
    this.showInputFields=false;
    this.disabledPracInput = true;
    this.practice_number = "";
    this.practice_orgname = "";
    this.practice_uniqueID = "";
    this.practice_service = "";
    this.practice_contactnumber = "";
    this.practice_physicaladdress = "";
    this.practice_postaladdress = "";
    this.emailValue = "";
    this.pracTypeList = [];
    this.successMessage = [];
    this.failureMessage = [];
    this.show();
    this.allavailableService = [];
    this.subscribedServices = [];
    this.practiceInfo = [];
    this.doctorInfo == undefined;
    this.uploadcerts = false;
    this.showPracticeSearch = false;
    this.showInputFields = false;
    this.practiceService.getPracticeSearch(this.practiceName, this.practiceNumber,this.apitoken).pipe(map((res: any) => {
    
      this.hide();
      this.practiceData = res;
      if(res == null){
        this.showPracticeSearch = true;
      }
      /*for(var i=0;i <this.practiceData.length;i++){
        for(var j=0;j <this.practiceData[i].uniqueId.length;j++){
          if(this.practiceData[i].uniqueId[j].practiceID != null){
            this.practiceData[i]["innetwork"] = true;
            console.log(" ID is " + this.practiceData[i].uniqueId[j].practiceID);
          }
        }
          
      }
*/
      
    })).subscribe((data) => {
      
    });
  }

  findDoctor(){
    if(this.doctorName==undefined){
      this.doctorName='';
    }
    if( this.doctorNumber==undefined){
      this.doctorNumber='';
    }
    this.show();
    
    $('#certificate').val('');
    $('#signature').val('');
    this.doctorInfo = [];
    this.successMessage = [];
    this.failureMessage = [];
    this.uploadcerts = false;
    if(this.practiceInfo != undefined ){
      if(this.practiceInfo.length != 0){
        this.doctorInfo = undefined;
        this.populatePracticeData(this.practiceInfo);
      }
    }else{
      this.allavailableService = [];
      this.subscribedServices = [];
      //document.getElementById("registeruser").setAttribute('disabled','true');
    }
    
   // this.allavailableService = this.practiceWithoutDoctor;
    this.doctorService.getDoctorSearch(this.doctorName, this.doctorNumber,this.apitoken).pipe(map((res: any) => {
      this.doctorData = res;
      
      this.hide();
    })).subscribe((data) => {
      
    });
  }

  getPracticeID(){
    
      this.ngOnInit(); 
    this.getPracticeidService.getPracticeId(this.practice_number,this.apitoken).pipe(map((res: any) => { 
      // alert(JSON.stringify(res));
       this.practiceList = res;
       if(this.practiceList.length == 0 || this.practiceList ==undefined){
         this.nopractice = true;
       }else{
         this.nopractice = false;
         //$("#practiceselection").prop('selectedIndex', this.practiceList.length);
        // (document.getElementById("practiceselection") as HTMLSelectElement).selectedIndex = this.practiceList.length;
         //this.changeDetector.detectChanges()
        }
     })).subscribe((data) => {
       

     });;
     if(this.docdoctorNumber =="" || this.docdoctorNumber == undefined){
      this.docdoctorNumber ='0';
     }
     if(this.showInputFields){
      this.allavailableService=[];
      this.finalService = [];this.allService = [];    
      this.subscribedServices = [];
      this.subsribeservice.getSubscriptionService(this.practice_number,this.docdoctorNumber,this.apitoken).pipe(map((subData: any) => {
        this.subscribedServices = subData;
        this.finalService=[];
        this.servicelist.getDataService(this.apitoken).pipe(map((subData: any) => {
          this.allService = subData;
          for(var i =0;i<subData.length;i++){
            if(this.doctorInfo == undefined  ){
              if(subData[i].serviceLevel == "DOCTOR"){
                subData[i]["disable"] = "true";
              }
            }
            if(subData[i].code!="CLICKDOC"){
              this.allavailableService.push(subData[i]);
              this.practiceWithoutDoctor.push(subData[i]);
            }
          }
          for (var i = 0; i < this.subscribedServices.length; i++) {
            if (this.subscribedServices[i].service.code != "CLICKDOC") {
              //this.showClickDocMessage = true;
              this.finalService.push({ "code": this.subscribedServices[i].service.code, "description": this.subscribedServices[i].service.description, "serviceLevel": this.subscribedServices[i].service.serviceLevel })
            }
            
          }
          const finalArr = this.allavailableService.filter(({ code }) =>
                  !this.finalService.some(exclude => exclude.code === code)
                );
                this.allavailableService = finalArr;
                this.hide();
            
        })).subscribe((data) => {
  
        });
       
      })).subscribe((data) => {
  
      });
     }
     

     
  }

  populatePracticeData(data){
    this.practiceInfo = data;
    this.practiceList = [];
    this.selected = data;
    this.allavailableService = [];
    this.nopractice =true;
    this.practiceWithoutDoctor = [];
    this.successMessage = [];
    this.failureMessage = [];
    this.uploadcerts = false;
    this.showInputFields = false;

    this.practice_number = this.practiceInfo.practiceNumber;
    this.practice_orgname = this.practiceInfo.orgName;
    this.practice_contactnumber = this.practiceInfo.practiceContactNumber;
    this.practice_physicaladdress = this.practiceInfo.physicalAddress;
    this.practice_postaladdress = this.practiceInfo.postalAddress;
    this.practice_service = this.practiceInfo.service;
    this.practice_uniqueID = this.practiceInfo.uniqueId;
    //document.getElementById("registeruser").removeAttribute('disabled');
    if(this.doctorInfo == undefined ){
      this.doctorSeachNumber = 0;
    }else{
      this.doctorSeachNumber =this.doctorInfo.doctorNumber;
    }
    this.show();
    this.allService = []
    this.getPracticeidService.getPracticeId(this.practiceInfo.practiceNumber,this.apitoken).pipe(map((res: any) => { 
      // alert(JSON.stringify(res));
       this.practiceList = res;
       if(this.practiceList.length == 0 || this.practiceList ==undefined){
         this.nopractice = true;
       }else{
         this.nopractice = false;
       }
     })).subscribe((data) => {
       

     });;
    this.subsribeservice.getSubscriptionService(this.practiceInfo.practiceNumber,this.doctorSeachNumber,this.apitoken).pipe(map((subData: any) => {
      this.subscribedServices = subData;
      this.finalService=[];
      this.allavailableService = [];
      this.servicelist.getDataService(this.apitoken).pipe(map((subData: any) => {
        this.allService = subData;
        for(var i =0;i<subData.length;i++){
          if(this.doctorInfo == undefined  ){
            if(subData[i].serviceLevel == "DOCTOR"){
              subData[i]["disable"] = "true";
            }
          }
          if(subData[i].code!="CLICKDOC"){
            this.allavailableService.push(subData[i]);
            this.practiceWithoutDoctor.push(subData[i]);
          }
        }
        for (var i = 0; i < this.subscribedServices.length; i++) {
          if (this.subscribedServices[i].service.code != "CLICKDOC") {
            //this.showClickDocMessage = true;
            this.finalService.push({ "code": this.subscribedServices[i].service.code, "description": this.subscribedServices[i].service.description, "serviceLevel": this.subscribedServices[i].service.serviceLevel })
          }
          
        }
        const finalArr = this.allavailableService.filter(({ code }) =>
                !this.finalService.some(exclude => exclude.code === code)
              );
              this.allavailableService = finalArr;
              this.hide();
          
      })).subscribe((data) => {

      });
     
    })).subscribe((data) => {

    });
  }

  populateDoctorData(data){
    this.doctorInfo = data;
    this.docfirstName = this.doctorInfo.firstName;
    this.doclastName = this.doctorInfo.lastName;
    this.docdisciplineDescription = this.doctorInfo.disciplineDescription;
    this.docdoctorNumber = this.doctorInfo.doctorNumber;
    this.docpersonalNumber = this.doctorInfo.personalNumber1;
    this.docphysicalAddress = this.doctorInfo.physicalAddress;
    this.docpostalAddress = this.doctorInfo.postalAddress;
    this.capturedocInput = false;
    this.selectedDoctor = data;
    this.allavailableService = [];
    this.username = this.doctorInfo.email;
    this.practiceWithoutDoctor=[];
    this.successMessage = [];
    this.failureMessage = [];
    this.disableDocInput = true;
    this.uploadcerts = false;
    $('#certificate').val('');
    $('#signature').val('');
    if(this.practice_number == undefined || this.practice_number == null || this.practice_number == ""){
      //alert("Please select the practice first")
     this.practiceNumberSearch = 0;
    }else{
      this.practiceNumberSearch = this.practice_number;
    }
    this.updateSection();
  }
  isActive(item) {
    return this.selected === item;
};

updateSection(){
  this.allavailableService=[];
  this.finalService = [];this.allService = [];
  this.show()
  this.subsribeservice.getSubscriptionService(this.practice_number,this.docdoctorNumber,this.apitoken).pipe(map((subData: any) => {
    this.subscribedServices = subData;
    this.servicelist.getDataService(this.apitoken).pipe(map((subData: any) => {
      this.allService = subData;
      for(var i =0;i<subData.length;i++){
        
        if(subData[i].code!="CLICKDOC"){
          this.allavailableService.push(subData[i]) ;
        }
      }
      for (var i = 0; i < this.subscribedServices.length; i++) {
        if (this.subscribedServices[i].service.code != "CLICKDOC") {
          //this.showClickDocMessage = true;
          
          this.finalService.push({ "code": this.subscribedServices[i].service.code, "description": this.subscribedServices[i].service.description, "serviceLevel": this.subscribedServices[i].service.serviceLevel })
        }
        if(this.subscribedServices[i].service.code == "E-SCRIPTING"){
          this.uploadcerts = true;
        }
        
      }
      const finalArr = this.allavailableService.filter(({ code }) =>
              !this.finalService.some(exclude => exclude.code === code)
            );
            this.allavailableService = finalArr;
            this.hide();
    })).subscribe((data) => {

    });
   
  })).subscribe((data) => {

  });
}
isActiveDoctor(item){
  return this.selectedDoctor === item;
  
}
  public fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  preview() {
    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    }
  }
  previewCert() {
    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewCertUrl = reader.result;
    }
  }

  onCertFileChange(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.previewCert();
  
  }

  toggleEditable(event, data) {
    //alert(event.target.id)
    if (event.target.checked) {
      document.getElementById(event.target.id).setAttribute('checked', 'true');
    }
    if (!event.target.checked) {
      document.getElementById(event.target.id).setAttribute('checked', 'false');
    }
    
  }

  logout(){
    location.href = environment.apInfo.authUrl+'authorize?response_type=code&client_id='+environment.apInfo.Key+'&scope=default&state=1234&redirect_uri='+environment.apInfo.callBack;
  }
   
  uploadCertificates(){
  var PracticeIdIndex  = (document.getElementById("practiceselection")) as HTMLSelectElement;
  var selectedPracticeIdIndex = PracticeIdIndex.selectedIndex;
    let byteNumbersCert;
  if (this.previewUrl != null) {
    this.previewUrl = this.previewUrl.toString();
    const byteCharactersCert = atob(this.previewCertUrl.replace(/^data:application\/(x-pkcs12);base64,/, ''));
     byteNumbersCert = new Array(byteCharactersCert.length);
    for (let i = 0; i < byteCharactersCert.length; i++) {
      byteNumbersCert[i] = byteCharactersCert.charCodeAt(i);
    }
  }else{
    byteNumbersCert = null;
  }
  let byteNumbers;
  if (this.previewCertUrl != null ) {
    const byteCharacters = atob(this.previewUrl.replace(/^data:image\/(png);base64,/, ''));
     byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
  }else{
    byteNumbers = null;
  }

    var data = {
      "practiceNumber": this.practiceNumber,
      "doctorNumber": this.doctorNumber,
      "uniqueId": document.getElementsByTagName("option")[selectedPracticeIdIndex].value,
      "aesKey": byteNumbersCert,
      "signature": byteNumbers
      }

      this.uploadcertificate.updateCertificates(data,this.apitoken).pipe(map((res: any) => {
        this.dialogmessage = "Certificate and signature successfully uploaded";
        $("#myModal").modal()
      })).subscribe((data) => {
  
   
      });;

  }
  passPracticeType(data) {
    this.practice_service= "";
    this.practice_service = data;
    this.pracList.getListService().pipe(map((res: any) => {
      res.forEach(element => {
        if (data == element.typename) {
         // alert(element.practype);
          this.pracCode = this.practiceTypeCode = element.practype;
        }

      });
    })).subscribe((data) => {

    });

  }
  generatePracticeIDModal(){
    if(this.applicationType == "" || this.applicationType == undefined){
      alert("please select application.");
      return;
    }
    var str = this.practice_orgname.replace(/[^a-zA-Z ]/g, "")
      this.generatePracticeidService.generatePracticeId(this.practice_number,str,this.practiceTypeCode,this.applicationType,this.apitoken).pipe(map((res: any) => {
        //res[0] = JSON.parse
        this.getPracticeID();
        this.hide();
        $("#generatePracticeModal").modal('hide');
      })).subscribe(data => {
      },
      err => alert("Not able to Create please contact to administrator")
      );;
  }
  selectedApplication(event){
    //alert(event);
    this.applicationType = event;
  }
  generatePracticeID(){
    if(this.showInputFields == false){
      this.disciplineID =  this.practice_service.match(/\[(.*?)\]/)[1];
      if (this.disciplineID.startsWith('0')){
        this.disciplineID = this.disciplineID.substring(1);
      }
      this.practiceTypeCode = this.disciplineID; 
    }

    //
      //var str = this.practiceInfo.orgName.replace(/[^a-zA-Z ]/g, "")
     
    $("#generatePracticeModal").modal()
    

    /*if(this.showInputFields){
      $("#generatePracticeModal").modal()
      //this.disciplineID =  this.practiceInfo.service.match(/\[(.*?)\]/)[1];
    }else{
      this.show();
      this.disciplineID =  this.practiceInfo.service.match(/\[(.*?)\]/)[1];
      if (this.disciplineID.startsWith('0')){
        this.disciplineID = this.disciplineID.substring(1);
      }
      var str = this.practiceInfo.orgName.replace(/[^a-zA-Z ]/g, "")
      this.generatePracticeidService.generatePracticeId(this.practiceInfo.practiceNumber,str,this.disciplineID,this.apitoken).pipe(map((res: any) => {
        
        this.practiceList = res[0];
        this.nopractice = false;
        this.hide();
      })).subscribe((data) => {
    
    
      });;
    }*/
    //service
    
  }
  
  registerUserData() {
  this.uploadcerts = false;
  const formDataCert = new FormData();
  this.subscriberdServices = [];
  //return;
  this.successMessage = [];
  this.failureMessage = [];
  const formData = new FormData();
  formData.append('file', this.previewUrl);
  let byteNumbersCert;
  if (this.previewUrl != null) {
    this.previewUrl = this.previewUrl.toString();
    const byteCharactersCert = atob(this.previewCertUrl.replace(/^data:application\/(x-pkcs12);base64,/, ''));
     byteNumbersCert = new Array(byteCharactersCert.length);
    for (let i = 0; i < byteCharactersCert.length; i++) {
      byteNumbersCert[i] = byteCharactersCert.charCodeAt(i);
    }
  }else{
    byteNumbersCert = null;
  }
  let byteNumbers;
  if (this.previewCertUrl != null ) {
    const byteCharacters = atob(this.previewUrl.replace(/^data:image\/(png);base64,/, ''));
     byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
  }else{
    byteNumbers = null;
  }
  var practiceEmail;var doctorEmail;
  if(this.hideEmail){
    practiceEmail = this.emailValue;
    doctorEmail = this.doctoremailValue;
  }else{
    practiceEmail = this.practiceInfo.email;
    doctorEmail = this.doctorInfo.email;
  }
  this.successMessage=[];this.failureMessage=[];
  //alert(document.getElementsByTagName("option")[selectedPracticeIdIndex].value);
  
  for (var i = 0; i < this.allavailableService.length; i++) {
    if (this.allavailableService[i].code != "CLICKDOC") {
    //   alert(document.getElementById(this.allavailableService[i].code).getAttribute('checked'))
      if (document.getElementById(this.allavailableService[i].code).getAttribute('checked') == "true") {
        this.subscriberdServices.push({ "code": this.allavailableService[i].code })
      }
    }
  }
  try{
    var PracticeIdIndex  = (document.getElementById("practiceselection")) as HTMLSelectElement;
    var selectedPracticeIdIndex = PracticeIdIndex.selectedIndex;
    if( this.practice_orgname=="" || this.docfirstName == "" || this.doclastName == "" || this.practice_physicaladdress=="" || this.practice_uniqueID =="" || this.practice_number =="" || document.getElementsByTagName("option")[selectedPracticeIdIndex].value == undefined){
     // this.dialogmessage = 
      $("#SubscribeModalError").modal()
      return;
    }
    if(this.subscriberdServices.length==0){
      this.dialogmessage = "Please select one or more subscriptions to register";
      $("#myModal").modal()
      return;
    }  
  }catch(err){
    //this.dialogmessage = "Please ensure that you have selected a practice number, doctor number and practice id.";
    $("#SubscribeModalError").modal()
    return
  }
  //this.show();
  
  
   
    let data = {
      "uniqueId": this.practice_uniqueID,//document.getElementsByTagName("option")[selectedPracticeIdIndex].value,
      "practiceEmail": practiceEmail,
      "PracticeName": this.practice_orgname,
      "practiceContactNumber": this.practice_contactnumber,
      "physicalAddress": this.practice_physicaladdress,
      "postalAddress": this.practice_postaladdress,
      "practiceNumber": this.practice_number,
      "doctorNumber": this.docdoctorNumber,
      "practiceType": this.pracCode,
      "sourceName": 'User/'+this.loggedin_user,
      "email": doctorEmail,
      "username": this.username,
      "password": "",
      "title": "",
      "firstname": this.docfirstName,
      "lastName": this.doclastName,
      "profiletype": "",
      "preferredlang": "",
      "gender": '',
      "services": this.subscriberdServices,
      "aesKey": byteNumbersCert,
      "signature": byteNumbers
    };
    this.registrationData = data;
  
  
  
  $("#registerModal").modal()
  /**/

}
continueRegister(){
  this.show();
  this.registerUser.registerUserService(this.registrationData,this.apitoken).pipe(map((res: any) => {
    
    this.subscriberdServices = [];
    this.registrationData = [];
    $("#registerModal").modal('hide');
    this.hide();
    for (var i = 0; i < res.serviceResponse.length; i++) {
      if(res.serviceResponse[i].status == "SUCCESS"){
        for(var j=0;j<this.allService.length;j++){
          if (this.allService[j].code == res.serviceResponse[i].code) {
            this.successMessage.push(this.allService[j].description);
            //this.changeDetector.detectChanges();
          }
        }
      }
      if (res.serviceResponse[i].status == "FAILED") {
        
          for(var j=0;j<this.allService.length;j++){
            try{
              var reason = JSON.parse(res.serviceResponse[i].message).responseMessages;
            }catch(err){
              reason = "Subscription unsuccessfull";
            }
            if (this.allService[j].code == res.serviceResponse[i].code) {
             
              this.failureMessage.push({
                "description":this.allService[j].description,
                "reason":reason
              });
              //this.changeDetector.detectChanges();
            }
          }

      }
    }
    this.updateSection();
  })).subscribe((data) => {
  
   
});;

}

}
