import { TokenService } from './token/token.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class RegisterServiService {

  constructor( private http: HttpClient) { }

  registerUserService(data,token) {
   
    let headers = 
    new HttpHeaders({'Authorization':'Bearer '+token});
    return this.http.post(environment.apInfo.apiUrl+'registrations/'+environment.apInfo.versionVar+'/subscribe', data,{headers:headers}).pipe(

    )
    //this.http.get('https://api-qa.cgmsa.co.za:443/registrations/V1.0/service');
  }
}
