import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class GeneratePracticeidService {
  constructor(private http: HttpClient) { }
 
  generatePracticeId(practiceNumber,practiceName,practceSample,appType,token) {
    let headers = 
    new HttpHeaders({'Authorization':'Bearer '+token});
    return this.http.get<void>(environment.apInfo.apiUrl+'registrations/uniqueId/'+environment.apInfo.versionVar+'/'+practiceNumber+'/'+practiceName+'/'+appType+'/'+practceSample+'/NULL',{headers:headers});
  }
}
