import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class DoctorsearchService {

  constructor(private http: HttpClient) { }

  getDoctorSearch(doctorName,doctorNumber,token) {
    //alert(environment.apiUrl)
    //return this.http.get<void>('https://api-qa.cgmsa.co.za:443/query/V1.0/doctorSearch/getDoctor?doctorNo='+doctorNumber+'&doctorDescription='+doctorName);
    let headers = 
    new HttpHeaders({'Authorization':'Bearer '+token});
    
    return this.http.get<void>(environment.apInfo.apiUrl+'query/'+environment.apInfo.doctorSearchVersion+'/doctorSearch/getDoctor?doctorNo='+doctorNumber+'&doctorDescription='+doctorName,{headers:headers});
  }
}
