import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UploadCertificateService {

  constructor(private http: HttpClient) { }
  updateCertificates(data,token){
    let headers = 
    new HttpHeaders({'Authorization':'Bearer '+token});
    return this.http.put(environment.apInfo.apiUrl+'registrations/'+environment.apInfo.versionVar+'/subscription/registration/E-SCRIPTING', data,{headers:headers}).pipe(

      )
  }
}
