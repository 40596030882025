import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import "../assets/JSON/practiceTypes.json";
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class PracticeTypeService {

  constructor(private http: HttpClient) { }
  listData;
  getListService() {
    
    this.listData = this.http.get<void>('../assets/JSON/practiceTypes.json');

    return this.listData;

  }
}
