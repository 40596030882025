import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivatenexionService {

  constructor(private _http:HttpClient) { }
  activate(token,data){
    console.log("Data to save " + JSON.stringify(data));
 
    let headers = 
    new HttpHeaders({'Authorization':'Bearer '+token});
      return this._http.put('https://api-stage.cgmsa.co.za/registrations/V2.0/subscription/registration/RECOMED', 
        data, { headers: headers });
        
      
  }
}
