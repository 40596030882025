import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private _http:HttpClient) { }
  callToken(code){
    let params = new URLSearchParams();   
    params.append('grant_type','authorization_code');
    params.append('client_id', environment.apInfo.Key);
    params.append('client_secret', environment.apInfo.Secret);
    params.append('redirect_uri', environment.apInfo.callBack);
    params.append('code', code);
 
    let headers = 
      new HttpHeaders({'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'});
      return this._http.post(environment.apInfo.apiUrl+'token', 
        params.toString(), { headers: headers });
        
      
  }
}
