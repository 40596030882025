import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class GetPracticeidService {

  constructor(private http: HttpClient) { }

  getPracticeId(practiceNumber,token) {
    let headers = 
    new HttpHeaders({'Authorization':'Bearer '+token});
    return this.http.get<void>(environment.apInfo.apiUrl+'registrations/uniqueId/'+environment.apInfo.versionVar+'/'+practiceNumber,{headers:headers});
  }
}
